/* ★″
 * Fx-EXTRA.js v1.0β
 * 
 * このディレクトリ内だけに適用したい機能
 */
 

;$(function(){


/*===========================================================================

	リンクの背景判定

===========================================================================*/

    $('#shopList ul li').each(function() {
	    
	    if ($(this).find('a')[0]){

			$(this).addClass('LINK');

	    }
    
    });




/*= end ===================================================================*/



/*===========================================================================

	アイコンの背景判定

===========================================================================*/

$('.layoutArticleList article dl').each(function() {
	    
	if ($(this).find('.icon')[0]){

	$(this).addClass('ICON');

	}

	if ($(this).find('.icon.limit')[0]){

	$(this).addClass('ICON').addClass('LIMIT');

	}

});




/*= end ===================================================================*/

});






