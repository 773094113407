/* ★″
 * Fx-BASIC.js v1.0β
 * 
 * 基本的な機能の実装
 */
 

;$(function(){

	
/*===========================================================================

	右クリック禁止

===========================================================================*/

/*
	$('body').contextmenu(function(e){
		e.preventDefault();
	});
*/


/*===========================================================================

	アンカーのズレに対処

===========================================================================*/

	
$(window).on('load', function() {

	var url = $(location).attr('href');

	if(url.indexOf("?id=") != -1){

		var id = url.split("?id=");
		var $target = $('#' + id[id.length - 1]);

		if($target.length){
			var pos = $target.offset().top;
			$("html, body").animate({scrollTop:pos}, 1500);
		}
	}
});


/*===========================================================================

	リンク画像を半透明ホバー

===========================================================================*/

	$("a img").hover(function(){
		$(this).fadeTo("normal", 0.75); // マウスオーバーで透明度を70%にする
	},function(){
		$(this).fadeTo("normal", 1.0); // マウスアウトで透明度を100%に戻す
	});



/*===========================================================================

	もっと読むの実装

===========================================================================*/

	// いろいろ出す
	function fxShowMore(){

	    $('.toggleMore').each(function() {
			$(this).show();
	    });
	    $('.buttonMore').each(function() {
			$(this).hide();
	    });
		
	}

   // いろいろ隠す
	function fxHideMore(){

	    $('.toggleMore').each(function() {
			$(this).hide();
	    });

	    $('.buttonMore').each(function() {
			$(this).show();
	    });

	    $('.buttonMore .open').each(function() {
			$(this).show();
	    });

	    $('.buttonMore .close').each(function() {
			$(this).hide();
	    });
		
	}
	
	// 出したり隠したりを制御
	function fxToggleMore(){
	
		fxShowMore();
	
		if(window.matchMedia("(max-width:767px)").matches){

			fxHideMore();
		
		}
	
	}

	// ロード時のトリガー
	$(window).on("load", function() { fxToggleMore(); });
	
	// リサイズ時のトリガー
	window.matchMedia("(min-width:768px)").addListener(fxToggleMore);

	// ボタンの動作・表示の制御
    $(".buttonMore li").on("click", function() {

		if ($(this).hasClass("open")){

	        $(this).hide();
	        $(this).next().fadeIn();
			$(this).closest("div").find(".toggleMore").slideDown();

		} else if ($(this).hasClass("close")){

	        $(this).hide();
	        $(this).prev().fadeIn();
			$(this).closest("div").find(".toggleMore").slideUp();

		}

    });


/*= end ===================================================================*/

});